<template>
  <div>
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>

    <div class="page">
      <div class="page__title">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  set,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
} from "date-fns";
import formApi from "@/apis/liff/v2/form";
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      form: null,
      formResult: null,
      isLoading: true,
      content: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchForm();
      this.check();
      this.isLoading = false;
    },
    async fetchForm() {
      try {
        let response = await formApi.getForm(this.$route.params.form_id);
        this.form = response.data.data;

        response = await formApi.getFormResult(this.$route.params.form_id);
        this.formResult = response.data.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
    async check() {
      if (this.form.type != 'event' || this.form.status == 'draft' || this.form.status == 'closed') {
        this.content = '找不到此活動'
        return;
      }

      const approvedStatus = ['approved', 'auto-approved'];
      if (!this.formResult || !approvedStatus.includes(this.formResult.status)) {
        this.content = '您尚未報名此活動'
        return;
      }

      if (this.formResult.checkin_status === 'attended') {
        this.content = '您已經報到過了'
        return;
      }

      const now = new Date();
      let startAt = this.form.config.event_setting.checkin_start_at;
      startAt = set(new Date(startAt), {
        hours: String(getHours(new Date(startAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(startAt))).padStart(2, "0"),
      });
      let endAt = this.form.config.event_setting.checkin_end_at;
      endAt = set(new Date(endAt), {
        hours: String(getHours(new Date(endAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(endAt))).padStart(2, "0"),
      });

      if (isBefore(now, startAt)) {
        this.content = '活動尚未開始'
        return;
      }

      if (isAfter(now, endAt)) {
        this.content = '此活動無法報到'
        formApi.setCheckinExpired(this.$route.params.form_id);
        return;
      }

      try {
        await formApi.setCheckinSuccess(this.$route.params.form_id);
        this.content = '報到成功'
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "報到失敗", "error");
      }
    },
  },
};
</script>
